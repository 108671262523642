@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;500;600;700&family=Poppins:ital,wght@0,100;0,400;0,700;1,400;1,700&family=Rubik:wght@300;400&display=swap') ;

.meals {
  align-items: center;
  background: #0fa36b;
  color: #464646;
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
  height: 100vh;
  justify-content: center;
  width: 100%;
}

.rocksGlass {
  animation: shake 1s infinite ease;
  max-height: 80vh;
}

.meals > .logo {
  animation: shake 1.5s infinite ease;
  color: aliceblue;
  font-family: Montserrat , sans-serif;
  font-size: 3em;
  font-weight: 900;
}

@keyframes shake {

  0% {
    transform: rotate(5deg);
  }

  50% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(5deg);
  }
}

@keyframes clink {

  0% {
    transform: rotate(-10deg);
  }

  50% {
    transform: rotate(20deg);
  }

  100% {
    transform: rotate(-10deg);
  }
}

:root {

  --laranja: #f81;

  --laranja-claro: #f4d06f;

  --branco: #fff8f0;

  --azul-claro: #9dd9d2;

  --azul: #392f5a;
}

*, *:after, *:before {
  box-sizing: border-box;
}

html {

  --font: 'Poppins' , sans-serif;

  --font-title: 'Poppins' , sans-serif;

  --font-size: 16px;

  --color: #392f5a;

  --color-light: #adf;

  --color-dark: #018;

  --radius: 0.2rem;
}

body {
  margin: 0 auto;
  max-height: 100vh;
  max-width: 100vw;
  min-height: 100vh;
  min-width: 100vw;
}

body, input, textarea, button {
  font-family: var(--font);
  font-size: var(--font-size);
}

h1, h2, h3 {
  font-family: var(--font);
}

h1, h2, h3, p, ul {
  margin: 1rem 0;
}

ul {
  list-style: square;
}

li {
  margin-bottom: 0.5rem;
}

label {
  display: block;
  margin-bottom: 0.25rem;
}

input:not( [ type = 'checkbox' ] ), textarea, select {
  background: #eee;
  border: 1px solid #ccc;
  border-radius: var(--radius);
  display: block;
  padding: 0.8rem;
  transition: 0.2s;
  width: 100%;
}

input:focus, textarea:focus {
  background: white;
  border-color: var(--color);
  box-shadow: 0 0 0 3px var(--color-light);
  outline: none;
}

button {
  background: var(--color);
  border: none;
  border-radius: var(--radius);
  color: var(--color-dark);
  cursor: pointer;
  padding: 0.8rem 1.6rem;
  transition: 0.1s;
}

hr {
  border: 2px solid black;
  margin: 2rem auto;
}

button:disabled {
  opacity: 0.5;
}

/* -----Login----- */
.loginPage {
  background-color: var(--laranja);
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  text-align: center;
}

.logoImg {
  align-self: center;
  width: 30vw;
}

.loginPage div {
  align-self: center;
  margin: 5px 0;
  width: 70vw;
}

.loginBtn {
  border-radius: 15px;
  color: var(--branco);
  font-weight: 700;
}

/* -----Header----- */
.Header {
  background-color: var(--laranja);
  width: 100vw;
}

.headerBar {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.divPadrao {
  height: 55.59px;
  visibility: hidden;
  width: 81.19px;
}

.headerBar h2 {
  font-size: 1.4rem;
  margin: 5px 0 8px;
}

.exploreIngr {
  font-size: 1.1rem;
  margin: 5px 0 8px;
}

.headerBtn {
  background-color: transparent;
  border: none;
  outline: none;
}

.headerSearchBar {
  align-items: center;
  background-color: var(--azul-claro);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100vw;
}

.headerSearchBar input[ type = 'text' ] {
  height: 40px;
  margin: 5px 0;
  width: 70vw;
}

.headerSearchBar button {
  border-radius: 15px;
  color: var(--branco);
  font-weight: 700;
  height: 40px;
  margin-bottom: 8px;
  padding: 0 20px;
}

.headerRadio {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 80vw;
}

.categoryBar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 8px;
  text-align: center;
  width: 100vw;
}

.categoryBtn {
  border-radius: 10px;
  color: var(--branco);
  font-size: 0.9rem;
  height: 30px;
  margin-bottom: 8px;
  padding: 0;
  width: 30vw;
}

/* -----Deck----- */
.deckSection {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 70px;
  padding-top: 20px;
  width: 100vw;
}

.recipeCard {
  align-self: center;
  background-color: var(--laranja-claro);
  border-radius: 20px;
  box-shadow: 4px 4px var(--azul);
  width: 80vw;
}

.recipeCard img {
  border-radius: 20px 20px 0 0;
  width: 100%;
}

.recipeCard p {
  color: var(--azul);
  font-size: 1.1rem;
  font-weight: 700;
  margin-top: 0;
  padding: 5px 0 5px 15px;
  width: 100%;
}

.videoSection {
  margin-bottom: 20px;
}

/* -----Explore----- */
.exploreSection {
  display: flex;
  flex-direction: column;
  height: 72vh;
  justify-content: space-between;
  text-align: center;
  width: 100vw;
}

.imgExplore {
  height: 250px;
  width: 100%;
}

.exploreSection button {
  border-radius: 15px;
  color: var(--branco);
  font-weight: 700;
  width: 60vw;
}

/* -----ExploreFood----- */
.exploreFoodSection {
  display: flex;
  flex-direction: column;
  height: 76vh;
  justify-content: space-between;
  text-align: center;
  width: 100vw;
}

.exploreFoodSection button {
  border-radius: 15px;
  color: var(--branco);
  font-size: 1rem;
  font-weight: 700;
  width: 60vw;
}

.exploreFoodSection p {
  font-size: 1rem;
}

/* -----ExploreIngredients----- */
.ingredientDeck {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 15px 0;
  padding-bottom: 80px;
  width: 100vw;
}

.ingredientCard {
  background-color: var(--azul-claro);
  border-radius: 15px;
  box-shadow: 4px 4px var(--azul);
  height: 147.5px;
  margin: 10px;
  text-align: center;
  width: 40vw;
}

.ingredientCard h2 {
  background-color: var(--laranja);
  border-radius: 0 0 15px 15px;
  color: var(--azul);
  font-size: 1rem;
  height: 47.5px;
  margin: 0 0 8px;
  padding: 5px 0;
  padding-left: 15px;
  text-align: start;
}

/* -----Recipe----- */
.recipeSection {
  display: flex;
  flex-direction: column;
}

.homeBtn {
  background-color: var(--laranja);
  border-radius: 15px;
  box-shadow: 3px 3px var(--azul);
  color: var(--azul);
  height: 30px;
  margin-left: 10px;
  margin-top: 20px;
  padding: 0;
  position: absolute;
  width: 30vw;
}

.infoRecipes {
  background-color: var(--branco);
  border-radius: 15px 15px 0 0;
  margin-top: 47vh;
  padding: 0 20px;
  padding-bottom: 70px;
  padding-top: 50px;
  position: absolute;
  width: 100vw;
}

.recipeHeader {
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  position: absolute;
  top: 280px;
  width: 100vw;
  z-index: 1;
}

.recipeTitle {
  background-color: var(--laranja-claro);
  border: solid 0.5px var(--azul);
  border-radius: 50px;
  box-shadow: 2.5px 2.5px var(--azul);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px 15px;
  width: 150px;
}

.recipeTitle h2 {
  font-size: 1.1rem;
  font-weight: 700;
  margin: 0;
}

.recipeTitle p {
  font-size: 1rem;
  margin: 0;
}

.recomendedCart img {
  width: 100%;
}

.doneCard img {
  border-radius: 20px 20px 0 0;
  width: 100%;
}

.interactionBtn {
  display: flex;
  justify-content: space-evenly;
}

.shareBtn {
  background-color: var(--branco);
  border: solid 0.5px var(--azul);
  border-radius: 50%;
  box-shadow: 2.5px 2.5px var(--azul);
  height: 40px;
  margin-right: 10px;
  padding: 7px;
  width: 40px;
}

.interactionBtn button {
  background-color: transparent;
  padding: 0;
}

.interactionBtn button img {
  height: 23px;
  width: 23px;
}

.copiedLink {
  position: absolute;
  top: 55px;
}

.btnDiv button {
  background-color: var(--azul);
  bottom: -2px;
  color: var(--branco);
  font-weight: 700;
  position: fixed;
  width: 100%;
}

.buttonFixed {
  bottom: 0;
  position: fixed;
  width: 100%;
}

.recomendedList {
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  text-align: center;
  width: 320px;
}

.image-container {
  position: relative;
  width: 960px;
}

.recomendedCart {
  float: left;
  margin: 0;
  padding: 0;
  width: 160px;
}

.recomendedCart p {
  background-color: var(--laranja-claro);
  color: var(--azul);
  font-size: 0.9rem;
  margin: 0;
  padding-left: 15px;
}

.recomendedCart h3 {
  background-color: var(--laranja-claro);
  color: var(--azul);
  font-size: 1rem;
  font-weight: 700;
  margin: 0;
  padding-left: 15px;
}

.button-container {
  text-align: center;
}

.button-container button {
  background-color: var(--branco);
  border: solid 0.5px var(--azul);
  border-radius: 50%;
  box-shadow: 2.5px 2.5px var(--azul);
  height: 40px;
  margin: 10px;
  padding: 7px;
  width: 40px;
}

.visible {
  display: inline-block;
}

.invisible {
  display: none;
}

.email {
  font-size: 1.2rem;
  font-weight: 400;
}

.checked {
  text-decoration: line-through;
}

.doneBtn {
  display: none;
}

.mainImage {
  width: 100vw;
}

/* -----Done Recipes----- */
.doneRecipesPage {
  display: flex;
  flex-direction: column;
}

.filterBtn {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10px;
  margin-top: 20px;
  width: 100vw;
}

.filterBtn button {
  border-radius: 10px;
  color: var(--branco);
  height: 30px;
  margin-bottom: 8px;
  padding: 0;
  width: 30vw;
}

.doneDeck {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100vw;
}

.doneCard {
  align-self: center;
  background-color: var(--laranja-claro);
  border-radius: 20px;
  box-shadow: 4px 4px var(--azul);
  margin-bottom: 20px;
  width: 80vw;
}

.doneCard h3 {
  color: var(--azul);
  font-size: 1.2rem;
  font-weight: 700;
  margin: 15px 0 0;
  padding: 5px 0 5px 15px;
  width: 100%;
}

.doneCard h2 {
  color: var(--azul);
  font-size: 1.1rem;
  margin: 0;
  padding: 5px 0 5px 15px;
  width: 100%;
}

.doneCard p {
  color: var(--azul);
  font-size: 1rem;
  margin: 0;
  padding: 5px 0 5px 15px;
  width: 100%;
}

.doneDateMeal {
  background-color: var(--azul-claro);
}

.doneDateDrink {
  background-color: var(--azul-claro);
  border-radius: 0 0 20px 20px;
}

.shareDoneBtn {
  background-color: var(--branco);
  border: solid 0.5px var(--azul);
  border-radius: 50%;
  box-shadow: 2.5px 2.5px var(--azul);
  height: 40px;
  margin-left: 240px;
  margin-top: -20px;
  padding: 5px;
  position: absolute;
  width: 40px;
}

.shareDoneBtn button {
  background-color: transparent;
  padding: 0;
}

.shareDoneBtn button img {
  height: 25px;
  width: 25px;
}

/* -----Favorite Recipes----- */
.interactionFavBtn {
  display: flex;
  justify-content: space-evenly;
  margin-left: 195px;
  margin-top: -103px;
  position: absolute;
  width: 25vw;
}

.shareFavBtn {
  background-color: var(--branco);
  border: solid 0.5px var(--azul);
  border-radius: 50%;
  box-shadow: 2.5px 2.5px var(--azul);
  height: 40px;
  margin-right: 10px;
  padding: 7px;
  width: 40px;
}

.interactionFavBtn button {
  background-color: transparent;
  padding: 0;
}

.interactionFavBtn button img {
  height: 23px;
  width: 23px;
}

/* -----Footer----- */
footer {
  background-color: var(--laranja-claro);
  bottom: 0;
  height: 55px;
  position: fixed;
  width: 100%;
}

.footerBar {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.footerBar button {
  background-color: transparent;
}

.footerBar button img {
  height: 30px;
}
